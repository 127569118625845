footer.site-footer {
	background: $navy; color:#fff;
	padding: 3rem 0;
	font-size: 15px; 
	div.label {  font-weight:700; font-style:italic; text-transform:uppercase;  }
	.copyright { padding-bottom: 0; padding-top: 1rem; font-size: 14px;  }
	
	ul.menu {
		list-style-type: none;
		padding: 0;
		li {  }
		li.bold > a { font-weight:700; font-style:italic; text-transform:uppercase; }	
		li.last { margin-bottom: 1.5rem; }  /* extra space in footer nav */			
		a { text-decoration:none; color:#fff; font-weight: 400;}
		a:hover { text-decoration: underline; }
		ul.sub-menu { padding:0; list-style-type:none }	
	}	

	#menu-footer-navigation-industries {  /* this menu needs 2 columns */
		display: -webkit-box; display: -ms-flexbox;  display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; 
		margin-right: -15px; margin-left: -15px; 
	} 
	#menu-footer-navigation-industries li { 
		-webkit-box-flex: 0; -ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%; 
		padding-right: 15px; padding-left: 15px; 
	}	
	
	ul.menu-social { 
		padding:0;
		li {
    		background: $navy; display: inline-block; text-align: center; 
			width: 32px; height: 40px; line-height: 40px; 
    		margin: 15px 4px 0; background: #fff; border-radius: 8px;
			a { color:#fff; font-size:30px; }			
		}
		.mdi:before { color:#fff; }
	} 
}

	
@media (max-width: 992px) {}
@media (max-width: 768px) {}
@media (max-width: 544px) {}