.menu-skip-links ul {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0;
    margin: 0;
    list-style: none;
}

body {
	font-family: 'Roboto', serif;
	font-weight: 400;
	font-size: 18px; 
	color: $body;
	min-height: 100%;
}

@media (min-width: 1200px) { .container { max-width: 1140px; } }  /* expand width */
@media (min-width: 1400px) { .container { max-width: 1340px; } }

section { margin: 3rem 0; }
/*section#content { margin: 1.5rem 0 3rem 0; } first section on page */
section.padding { padding: 3rem 0; }

.main {
	padding-left: 0 !important;
	padding-right: 0 !important;
}
header.page-header { margin-bottom: 3rem; } 

h1, .h1 {
	color: $brand-primary;
	font-family: 'Oswald', sans-serif;
	font-weight: 300;
	font-size: 2.6rem !important;
	@include media-breakpoint-down(sm) {
		font-size: 2rem !important;
	}
}
h2, .h2 {
	color: $brand-primary;
	font-family: 'Oswald', sans-serif;	
	font-weight: 700; 
	font-size: 2.25rem !important;
}
.main h2 a { color: $brand-primary !important; }
h3, .h3 {
	color: $brand-secondary;
	font-weight: 700;
	font-size: 1.33rem !important;
}

.larger { font-size:2rem; }

a:focus, button:focus { outline: none; }
a.link { text-decoration:underline; }
a.noline { text-decoration:none}
a:hover.noline { text-decoration:underline; }
li.nolink > a, a.nolink {cursor: default; }

a { color: $blue; }
.main a { color: $blue; text-decoration:underline; } 
.main a.btn { text-decoration:none; }
.main h2 a { text-decoration:none; }	
.main h2 a:hover { text-decoration:underline; }

button::-moz-focus-inner { border: 0; }
/*.btn { border-radius: 0 !important; } */

img {
	max-width: 100%;
	height: auto;
	display: block;
}
div.caption { font-size:16px; line-height:1.2; font-style:italic; }

.navy	{ color:$navy; } .bg-navy { background-color: $navy; } 
.blue	{ color:$blue; } .bg-blue { background-color: $blue; } 
.light-blue	{ color:$light-blue; } .bg-blue { background-color: $light-blue; }
.bright-blue	{ color:$bright-blue; } .bg-blue { background-color: $bright-blue; }
.gray	{ color: $gray; } .bg-gray { background-color: $gray; } 
.light-gray { color: $light-gray; } .bg-light-gray { background-color: $light-gray; }
.orange	{ color: $orange; } .bg-orange { background-color: $orange; } 


form.search-form { border:1px solid $gray; border-radius: 0.3rem; }
form.search-form .search-field { border:0; font-size:.9rem;  }
form.search-form input[type="submit"] { background:url('/wp-content/uploads/2023/05/magnify.png') no-repeat; text-indent: -9999em; border:0; padding: 0; width:30px; }
form.search-form .search-submit:hover {  background-color: #fff; border-color: $gray; }	

/* .form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;

  > .col,
  > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
} */



/*
 * Description: Functions and Mixins
 * Instructions: Set a rem font size with pixel fallback
 * Usage:
 * p {
 *   @include font-size(14px)
 * }
*/ 

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

/* Description: SVG background images with PNG and retina fallback
 * Instructions: You need one .svg file, that will serve as the default background image. You’ll also need a regular .png that serves as a fallback for non-svg-supporting browsers. And last you need a twice as large .png as a second fallback to retina screens.
 * Usage:
 * body {
 * 	@include background-image('pattern');
 * }
*/

 $image-path: '../img' !default;
 $fallback-extension: 'png' !default;
 $retina-suffix: '@2x';
 @mixin background-image($name, $size:false){
  background-image: url(#{$image-path}/#{$name}.svg);
  @if($size){
    background-size: $size;
  }
  .no-svg &{
    background-image: url(#{$image-path}/#{$name}.#{$fallback-extension});

    @media only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
      background-image: url(#{$image-path}/#{$name}#{$retina-suffix}.#{$fallback-extension});
    }
  }
}

/* Description: Animations and keyframes
 * Instructions: Animations are always a pain to create with all the vendor prefixes and what not. But with the help of this mixin it will boil down to just a few lines of code.
 * Usage:
 * @include keyframes(slide-down) {
 *  0% { opacity: 1; }
 *  90% { opacity: 0; }
 * }
 * .element {
 *   width: 100px;
 *   height: 100px;
 *   background: black;
 *   @include animation('slide-down 5s 3');
 * }
*/

 @mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }  
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }  
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};      
}

/* Description: Transitions
 * Instructions: As with animations, transitions also make your code quite bloated which can hurt the readability. But this is also solved by using a mixin for it.
 * Usage:
 * a {
 *   color: gray;
 *   @include transition(color .3s ease);
 *   &:hover {
 *     color: black;
 *   }
 * }
 */

 @mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

/* Description: Cross browser opacity
 * Instructions: This mixin ensures cross browser opacity all the way down to Internet Explorer 5. Though if you have to optomize for IE5, you have a lot bigger problems than opacity, godspeed my friend.
 * Usage:
 * .faded-text {
 *   @include opacity(0.8);
 * }
 */

 @mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

/* Description: Clearfix
 * Instructions: It's a clearfix
 * Usage:
 * .container-with-floated-children {
 *   @extend %clearfix;
 * }
 */

 %clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

/* Description: Visually hide an element
 * Instructions: When you hide an element with display: none, that prevents screen readers from reading it to the user. Sometimes that’s fine, but in other cases this will make the site hard to use for people with screen readers. Thus, we have to use another technique for hiding elements while at the same time make them accessible.
 * Usage:
 * <button class="mobile-navigation-trigger">
 *   <b class="visually-hidden">Open the navigation</b>
 *   <img src="img/mobile-navigation-icon.svg">
 * </button>
 * .visually-hidden {
 *   @extend %visuallyhidden;
 * }
 */

 %visuallyhidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

/* Description: Quick gradients
 * Instructions: Easily add gradients defining only start color, end color, and gradient type. Add all the gradient properties and you can choose as needed. The gradient type allows you to choose from a vertical gradient, horizontal gradient, or a radial (sphere shaped) gradient.
 * Usage:
 * foo {
 *   @include background-gradient(red, black, 'vertical');
 * }
 */

 @mixin background-gradient($start-color, $end-color, $orientation) { 
  background: $start-color;

  @if $orientation == 'vertical' {
    background: -webkit-linear-gradient(top, $start-color, $end-color);
    background: linear-gradient(to bottom, $start-color, $end-color);
    } @else if $orientation == 'horizontal' {
      background: -webkit-linear-gradient(left, $start-color, $end-color);
      background: linear-gradient(to right, $start-color, $end-color);
      } @else {
        background: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color);
        background: radial-gradient(ellipse at center, $start-color, $end-color);
      }
    }

/* Description: External fonts
 * Instructions: Using the CSS font-face property, we can easily add fonts in a directory and include all font types. When using font files, different browsers have different compatibility with file types. This mixin helps solve that by using any relevant font file in a directory.
 * Usage:
 *  @include font-face('gotham', '/fonts/gotham');
 */

 @mixin font-face($font-name, $file-name, $weight: normal, $style: normal) { 
  @font-face {
    font-family: quote($font-name);
    src: url($file-name + '.eot');
    src: url($file-name + '.eot?#iefix')  format('embedded-opentype'),
    url($file-name + '.woff') format('woff'),
    url($file-name + '.ttf')  format('truetype'),
    url($file-name + '.svg##{$font-name}')  format('svg');
    font-weight: $weight;
    font-style: $style;
  }
}

/* Description: Pseudo
 * Instructions: When using ::before and ::after you'll always need these three, so we're saving two lines of code every time you use this. Basically just a shortcut tool.
 * Usage:
 * div::after {
 *   @include pseudo;
 *   top: -1rem; left: -1rem;
 *   width: 1rem; height: 1rem;
 * }
 */

 @mixin pseudo($display: block, $pos: absolute, $content: ''){
  content: $content;
  display: $display;
  position: $pos;
}

/* Description: Responsive ratio
 * Instructions: We use this for creating scalable elements (usually images / background images) that maintain a ratio.
 * Usage:
 * div {
 *   @include responsive-ratio(16,9);
 * }
 */

 @mixin responsive-ratio($x,$y, $pseudo: false) {
  $padding: unquote( ( $y / $x ) * 100 + '%' );
  @if $pseudo {
    &:before {
      @include pseudo($pos: relative);
      width: 100%;
      padding-top: $padding;
    }
  } @else {
      padding-top: $padding;
  }
}

