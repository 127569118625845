// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$body:					#000;   /*#181818;  */
$very-light-gray:		#eaeaea;
$light-gray:			#b8b8b4;
$almost-gray:			#c0c0c0;
$gray:					#b8b8b4;  /* rgb: 184 184 180  */
$dark-gray:				#333;

$navy:					#080c27;
$blue:					#5b8abe;  
$light-blue:			#ecf1f5;
$bright-blue:			#00aeef;
$royal:					#2b66c0;
$white:					#ffffff;
$black:					#181818;
$red:					#f12727;
$gold:					#ffd70d;
$orange:				#f7941e;

$brand-primary:			$blue;
$brand-primary-text:	$white;
$brand-secondary: 		$navy;
$brand-secondary-text:	$white;

// gradients
$primary-gradient: linear-gradient(#0d3b4f,#0b3142,#092735);

$test-gradient:			linear-gradient(to right, $very-light-gray, lighten($light-gray, 1%) 30%, $very-light-gray 80%, $white);
$test-gradient-secondary:  linear-gradient(135deg, darken($brand-secondary, 10%), $brand-secondary 20%, $brand-secondary 60%, lighten($brand-secondary, 15%));

// Box Shadows
$box-shadow-standard:   		0 2px 4px 0 rgba(0, 0, 0, 0.25), 0 2px 8px 0 rgba(0, 0, 0, 0.20);

$box-shadow-button:				0 2px 4px 0 rgba(0, 0, 0, 0.25), 0 2px 8px 0 rgba(0, 0, 0, 0.20);
$box-shadow-button-hover: 		0 5px 9px 0 rgba(0, 0, 0, 0.30), 0 4px 12px 0 rgba(0, 0, 0, 0.25);

$dp-1: 		0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
$dp-2: 		0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
$dp-3: 		0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12);
$dp-4:  	0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
$dp-5:  	0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
$dp-6:  	0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
$dp-7:  	0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
$dp-8:  	0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
$dp-9:  	0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
$dp-10:  	0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
$dp-11:  	0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
$dp-12:  	0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
$dp-13:  	0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
$dp-14:  	0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
$dp-15:  	0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
$dp-16:  	0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
$dp-17:  	0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
$dp-18:  	0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
$dp-19:  	0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
$dp-20:  	0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
$dp-21:  	0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
$dp-22:  	0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
$dp-23:  	0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
$dp-24:  	0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);

// Slick override
$slick-font-path: "../fonts/";

.order-1 {
	-webkit-box-ordinal-group: 2;
	-ms-flex-order: 1;
	order: 1;
}
.order-2 {
	-webkit-box-ordinal-group: 3;
	-ms-flex-order: 2;
	order: 2;
}
.order-3 {
	-webkit-box-ordinal-group: 4;
	-ms-flex-order: 3;
	order: 3;
}
.order-4 {
	-webkit-box-ordinal-group: 5;
	-ms-flex-order: 4;
	order: 4;
}
.order-5 {
	-webkit-box-ordinal-group: 6;
	-ms-flex-order: 5;
	order: 5;
}
.order-6 {
	-webkit-box-ordinal-group: 7;
	-ms-flex-order: 6;
	order: 6;
}
.order-7 {
	-webkit-box-ordinal-group: 8;
	-ms-flex-order: 71;
	order: 7;
}
.order-8 {
	-webkit-box-ordinal-group: 9;
	-ms-flex-order: 8;
	order: 8;
}
.order-9 {
	-webkit-box-ordinal-group: 10;
	-ms-flex-order: 9;
	order: 9;
}
.order-10 {
	-webkit-box-ordinal-group: 11;
	-ms-flex-order: 10;
	order: 10;
}
.order-11 {
	-webkit-box-ordinal-group: 12;
	-ms-flex-order: 11;
	order: 11;
}
.order-12 {
	-webkit-box-ordinal-group: 13;
	-ms-flex-order: 12;
	order: 12;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-5%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInFromRight {
  0% {
    transform: translateX(5%);
  }
  100% {
    transform: translateX(0);
  }
}